import React from "react";

export default function Error() {
  return (
    <div>
      <center>
        <br />
        <h3>This site is temporarily down</h3>
      </center>
    </div>
  );
}

import React from "react";
import Error from "./components/Error";
import MetaTags from "./components/Metatags";

const App = () => {
  return (
    <div>
      <MetaTags
        title="Build The World"
        description="BUILDtheworld is a social network that connects people, social organizations,
            and corporates to harness the energy of today's youth to the shared ambition of inclusive development.
            The platform will direct existing impact resources into new channels of involvement and contribution,
            leading to the greatest social good."
        url="https://buildtheworld.org"
        imageUrl="https://buildtheworld.org/assets/testBanner-web.jpg"
      />
      <Error />
    </div>
  );
};
export default App;

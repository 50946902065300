import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const MetaTags = ({ title, description, url, imageUrl }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="BUILDTheWorld" />
    </Helmet>
  );
};
MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};
export default MetaTags;
